import {DynamicPaymentMethodsShape} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/productPageSettings.enums';
import {ButtonStyle} from '@wix/ecom-platform-express-checkout-buttons';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ProductService} from './ProductService';
import {round} from 'lodash';
import {clickOnCheckoutWithEWallet} from '@wix/bi-logger-ec-site/v2';
import {ProductType} from '../constants';
import {VolatileCartService} from './VolatileCartService';
import {CheckoutType, DirectPurchaseService} from './DirectPurchaseService';
import {IProductDTO, UserInput} from '../types/app-types';

export class CashierExpressService {
  constructor(private readonly siteStore: SiteStore, private readonly volatileCartService: VolatileCartService) {
    //
  }

  public getInitialProps(
    product: IProductDTO,
    dynamicPaymentMethodsButtonShape: DynamicPaymentMethodsShape,
    checkoutCurrencies: string[]
  ): {requestShipping: boolean; buttonStyle: ButtonStyle; currency: string} {
    return {
      requestShipping: product.productType !== ProductType.DIGITAL,
      buttonStyle: {
        shape: dynamicPaymentMethodsButtonShape === DynamicPaymentMethodsShape.pill ? 'pill' : 'rect',
        height: 42,
      },
      currency: this.getCurrencyForCashierProps(checkoutCurrencies),
    };
  }

  private getCurrencyForCashierProps(checkoutCurrencies: string[]) {
    return checkoutCurrencies?.includes(this.siteStore.getCurrentCurrency())
      ? /* istanbul ignore next */ this.siteStore.getCurrentCurrency()
      : this.siteStore.currency;
  }

  public readonly getEstimatedPrice = (
    product: IProductDTO,
    selectedVariant: IOptionSelectionVariant,
    userInputs: UserInput
  ): string => {
    const quantity = ProductService.getQuantity(userInputs);
    const actualProduct = selectedVariant || product;
    const calculatedPrice = round((actualProduct.comparePrice || actualProduct.price) * quantity, 2);

    return `${calculatedPrice}`;
  };

  public async fetchInitialDataAndCreateVolatileCart(
    product: IProductDTO,
    userInputs: UserInput,
    isPreOrderState: boolean
  ) {
    await this.volatileCartService.getStandaloneCheckoutIds(product, userInputs, CheckoutType.Cashier, isPreOrderState);
  }

  public logBiClickOnCheckoutWithEWallet(product: IProductDTO) {
    const biEvenProps = {
      origin: 'product page',
      productsList: product.id,
      cartId: this.volatileCartService.cartId,
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.webBiLogger.report(
      clickOnCheckoutWithEWallet({
        ...biEvenProps,
        catalogAppId: '215238eb-22a5-4c36-9e7b-e7c08025e04e',
        checkoutId: this.volatileCartService.checkoutId,
      })
    );
  }

  public async createCheckout(product: IProductDTO, userInputs: UserInput, isPreOrderState: boolean): Promise<string> {
    await this.fetchInitialDataAndCreateVolatileCart(product, userInputs, isPreOrderState);

    return this.volatileCartService.checkoutId;
  }

  public handleExpressCashierOnClick(product: IProductDTO, directPurchaseService: DirectPurchaseService): void {
    directPurchaseService.handleExpressCashierOnClick(product);
  }
}
